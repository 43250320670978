.offie-dropdown {
  min-width: 160px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.1s 0.15s, transform 0.25s, visibility 0s 0.25s, z-index 0s 0s;
  visibility: hidden;
  z-index: 10;

  &.dropdown-menu {
    display: block; // fix compatibility with dropdown-menu from bootstrap
  }

  &.offie--active {
    opacity: 1;
    transition-delay: 0s; // For visibility to kick-out: http://www.greywyvern.com/?post=337
    visibility: visible;
  }

  .offie-block {
    background-color: #fff;
    border: 1px solid $border-color;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07);
  }

  .nav-link {
    white-space: nowrap;
  }
}

.offie-collapse {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.25s;
  visibility: hidden;

  &.offie--active {
    opacity: 1;
    visibility: visible;
  }
}
