.mediaobj--performer,
.mediaobj--spokesperson {
  > .mediaobj-object {
    font-size: 20px;

    > img {
        -moz-border-radius: 40px;
        -webkit-border-radius: 40px;
      border-radius: 40px;
      border: 1px solid #eee;
      width: 40px;
    }
  }
}

.mediaobj--performer {
  .mediaobj-title {
    color: $brand-primary;
    font-weight: 600;
  }
}

.mediaobj--eventinfo {
  > .mediaobj-object {
    margin: 10px 0;
    margin-right: 10px;

    .wizicon {
      @include size(20px, 20px);
      fill: $brand-primary;
      line-height: 1;
    }
  }

  .mediaobj-title {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 700;
    line-height: 20px;
  }
}
