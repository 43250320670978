.label {
  line-height: 1;
  text-decoration: none;
}

.label--tags {
  background: $brand-primary;
  border: 1px solid $brand-primary;
  border-radius: 0;
  color: #fff;
  font-size: $font-size-xxs;
  padding: .25em .6em;
  text-transform: uppercase;

  &[href]:active,
  &[href]:focus,
  &[href]:hover {
    background: $brand-primary;
    color: #fff;
  }
}

.label--investors {
  border-radius: 0;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  font-size: $font-size-xxs;
  padding: .25em .6em;
  text-transform: uppercase;
}

.card-element--tags .label--investors + .list--tags {
  display: inline-block;
}
