.gallery .gallery-item {
  margin-bottom: 20px;
}

.gallery-list .gallery-item:last-child {
  margin-bottom: 0;
}

.gallery.gallery-list {
  .gallery-item {
    > .panel {
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.gallery--feed-facebook {
  @include list-unstyled();

  .gallery-item {
    margin-bottom: 5px;
  }
}

.gallery--deck {
  @include list-unstyled();
  @include make-grid-layout(5px, '.gallery-item');
  position: relative;

  .gallery-item {
    margin-bottom: 10px; // 5px * 2
    width: (100% / 6);
  }

  .gallery-clearfix {
    @include clearfix();
  }

  .card,
  .gallery-item {
    position: static;
  }

  .card-img {
    &,
    img {
      height: auto;
      width: auto;
    }
  }

  .offie-dropdown {
    left: 0;
    padding-top: 7px;
    opacity: 1;
    right: 0;
    transform-origin: top;
    transform: scaleY(0);

    &.offie--active {
      transform: scaleY(1);
    }
  }

  .js-gallery-pusher {
    transition: height 0.3s;
  }
}
.collapse {
  .card-asset.card-asset--grid {
    .card-block {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

.gallery-lg {
  @include row-size(40px);
}
