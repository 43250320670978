.panel {
  background-color: transparent;
  border-width: 0;

  .panel-heading {
    padding: 0;

    a {
      cursor: pointer;
      display: block;
    }
  }

  .panel-title {
    padding: 15px 15px;
    overflow: hidden; // this makes the clearfix from floated.
  }

  .panel-title-text {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .panel-title-icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  .panel-text {
    color: $text-muted;
    font-family: $font-family-primary;
    margin: 0;
    margin-bottom: 20px;
  }

  .panel-widget-item {
    display: inline-block;
    padding: 15px 15px;
    vertical-align: top; // Tricky thing to get rid of inline-block space.
  }
}

.collapse-body,
.panel-body {
  @include clearfix();
}

.panel-group .panel + .panel {
  margin-top: 0px; // Resets bootstrap.
}
