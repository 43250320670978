.section {
  background: $section-bg;
  margin: $section-margin;
  padding: 0; // Resets CSSR padding on this, @see .section-block.
  position: $section-position;
}

.section-container {
  @extend .container;
}

.section-wrapper {
  position: relative;
}

.section-block {
  background: $section-block-bg;
  position: $section-block-position;
}

.section-title {
  margin: 0;
  margin-bottom: $section-spacer-y;
}

.section-subtitle {
  margin-bottom: $section-spacer-y * (2/3);
  margin-top: 0;
}

.section-text {
  margin: 0;
  margin-bottom: $section-spacer-y;
}

.section-block {
  padding: $section-spacer-y $section-spacer-x;

  &.section-block-grid {
    padding-bottom: 0;
  }
}

.section-btn,
.section-element {
  margin-bottom: $section-spacer-y;
}

.section-block,
.section-header,
.section-footer {
  > *:last-child {
    margin-bottom: 0;
  }
}

.section-btn {
  @extend #{$section-btn-prototype};
}

.section-img {
  &,
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.section-header,
.section-footer {
  padding: $section-cap-y $section-cap-x;
}

.section-title {
  border-bottom: $section-title-border;
  font-family: $section-title-font-family;
  font-size: $section-title-font-size;
  font-weight: $section-title-font-weight;
  line-height: $section-title-line-height;
  text-align: $section-title-text-align;
  text-transform: uppercase;
}

.section-header {
  background: $section-header-bg;
}

.section-footer {
  background: $section-footer-bg;
  text-align: center;
}
