@mixin webidentity-sizing($width, $border-radius, $padding) {
  &,
  &.mediaobj-object {
    border-radius: $border-radius;
    height: $width;
    padding: $padding;
    width: $width;
  }

  > .wizicon {
    display: block;
    height: 100%;
    width: 100%;
  }
}

@mixin webidentity-huer(
  $bg-color,
  $border-color,
  $color,
  $bg-color-hover: '',
  $border-color-hover: '',
  $color-hover: ''
) {
  background-color: $bg-color;
  border: 1px solid $border-color;

  > .wizicon path {
    fill: $color;
  }

  &:focus,
  &:hover {
    background-color: $bg-color-hover;
    border: 1px solid $border-color-hover;

    > .wizicon path {
      fill: $color-hover;
    }
  }
}
