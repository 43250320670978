.pagination > li > a,
.pagination > li > span {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: $link-color;

  .wizicon {
    fill: $link-color;
  }
}

.pagination > .active > span,
.pagination > .active > span:hover {
  color: #717070 !important;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover {
  color: #777 !important;
  cursor: not-allowed;
}
