@mixin make-block-center($width) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: $width;
}

@mixin make-columns($nbColumns, $gap-size) {
    -moz-columns: $nbColumns;
    -webkit-columns: $nbColumns;
  columns: $nbColumns;

    -moz-column-gap: $gap-size;
    -webkit-column-gap: $gap-size;
  column-gap: $gap-size;
}
