.card {
  background-color: transparent;
  position: relative;
}

.card-title {
  line-height: 1.2; // Resets something from redcurrents.css
  overflow-wrap: break-word;

  a {
    @extend .link-reset; // .link-default ?
  }
}

.card-img {
  position: relative;

  &,
  img {
    max-width: 100%;
    width: 100%;
  }
}

.card-block > *:last-child {
  margin-bottom: 0 !important; // !important is required to ensure we don't have double space below for the last element.
}

.card-block .card-img {
  margin-bottom: $card-spacer-y;
}

.card-link {
  font-weight: $card-link-font-weight;
}

.card-btn {
  @extend #{$card-btn-prototype};
}

// Default sizing.
@include card-sizing($card-spacer-x, $card-spacer-y);
