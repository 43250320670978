.svgfilters {
  display: none; // hide the .svgfilters element, filters are referenced via CSS.
}

.layout-hook.layout-hook--content {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: $screen-sm) {
    .section-wrapper {
      max-width: $container-sm - $grid-gutter-width - 80px;
    }
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      max-width: $container-md - $grid-gutter-width - 200px;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      max-width: $container-lg - $grid-gutter-width - 200px;
    }
  }
}

.layout--show {
  .layout-hook--main {
    .section--jumbotron-content.section--odd { // 1 is odd, first is always white!
      background-color: #fff;
    }
  }
}

.content-text {
  // Base for vertical rythm: everything will be a multiple of this.
  $font-size: 16px;
  $line-height: 1.6 * $font-size;

  // Setup base style.
  font-size: $font-size;
  line-height: $line-height;

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5
  h6, .h6 {
    line-height: 1.25;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 28px;
    margin-top: 2 * $line-height;
    margin-bottom: 0.75 * $line-height;
  }

  h3 {
    font-size: 24px;
    margin-top: 1.5 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  h4 {
    font-size: 18px;
    margin-top: 1.25 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  // Handle special-case when two titles are following each other.
  h2 + h2 {
    margin-top: 0.75 * $line-height;
  }

  h2 + h3,
  h3 + h3,
  h3 + h4,
  h4 + h4 {
    margin-top: 0.5 * $line-height;
  }

  p {
    margin-top: 0;
    margin-bottom: $line-height;
  }

  // Type ?
  h2, h3 {
    font-weight: 400;
  }

  table td,
  table th {
    padding: 5px 10px;
  }
}
