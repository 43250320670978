.list {
  @include list-unstyled();
}

.list--horizontal {
  @include list-component(10px);
}

.list--vertical {
  margin-left: -5px;

  .list-item {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 7px;
  }
}

.list--tags {
  font-size: $font-size-base;
  display: inline-block;

  .list-item {
    display: inline-block;
    margin-bottom: 5px;
  }
}

.list--share {
  @extend .list--vertical;
}

.list--assets {
  .list-item:first-child {
    .list-item-element {
      border-top-width: 0;
    }
  }

  .list-item-link {
    @extend .link-default;

    font-weight: 600;
  }

  .list-header-element {
    border-bottom-width: 0;
    color: $text-muted;
    font-family: $font-family-primary;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .list-item:nth-child(even) .list-item-element {
    background-color: $brand-light-lter;
  }

  .list-item:nth-child(odd) .list-item-element {
    background-color: $brand-light-dk;
    border-right: 1px solid $brand-white;
  }

  .list-item .list-item-element .wizicon {
    fill: $brand-primary;
  }

  .list-item-element {
    border-top-width: 0;
    padding: 12px 15px;
    vertical-align: middle;
  }

  .list-item-element--type {
    .wizicon {
      @include size(30px, 30px);
      fill: $brand-dark-lter;
    }
  }

  .list-item-element--title {
    font-weight: 600;
  }

  .list-item-element--tags {
    .list--tags {
      margin-bottom: 0;
    }
  }

  .list-item-element--date {
    white-space: nowrap;
  }
}

.list--webidentities {
  @extend .list--vertical;
}

.list--content-download-1 {
  @include list-unstyled();

  margin-bottom: 0;

  .list-item {
    padding: 10px 15px;
    text-align: center;
  }

  .list-item + .list-item {
    border-top: 1px solid $border-color;
  }
}

.list--content-download-2 {
  @media screen and (min-width: $grid-float-breakpoint) {
    @include make-grid-layout($grid-gutter-width / 2, '.list-item');

    .list-item {
      width: 50%;
    }

    .btn {
      display: block;
    }
  }
}

.list--event_web_publication-anchors {
  @include list-unstyled();
  text-align: center;

  .list-item {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .list-item {
      display: inline-block;

    }

    .list-item + .list-item {
      margin-left: 20px;
    }
  }
}

.list--event_web_publication--properties {
  @media screen and (min-width: $grid-float-breakpoint) {
    @include make-grid-layout($grid-gutter-width / 2, '.list-item');

    .list-item {
      width: 33%;

      &:first-of-type {
        .mediaobj--eventinfo {
          text-transform: capitalize;
        }
      }
    }
  }
}
