.form--name {
  float: left;
  margin-right: 20px;
  width: 100%;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-right: 0;
    width: 45%;

    &:first-child {
      margin-right: 10%;
    }
  }

  @media screen and (min-width: $screen-md) {
    margin-right: 20px;
    width: 25%;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.form--email {
  display: inline-block;
  width: 100%;

  @media screen and (min-width: $grid-float-breakpoint) {
    width: 100%;
  }

  @media screen and (min-width: $screen-md) {
    width: 45%;
  }
}

.form-group--captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.section--searchform {
  .form-element--pattern,
  .form-element--datepicker {
    input {
      border-right: none;

      &:focus {
        border-color: $brand-primary;

        +.input-group-addon {
          border: 1px solid $brand-primary;
          border-left: none;
        }
      }
    }
  }

  .form-submit {
    @include make-button--primary-1();
    @include size(100%, 38px);
  }

  @media screen and (max-width: $screen-md-max) {
    .form-element:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.datetimepicker {
  .fa-arrow-left, .fa-arrow-right {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../../images/svgicons/collapse-indicator.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  .fa-arrow-left {
    transform: rotate(90deg);
  }

  .fa-arrow-right {
    transform: rotate(-90deg);
  }
}
