// Brand
$brand-primary:   #be1922;
$brand-secondary: #7E8083;
$brand-light:     #f6f7fd;
$brand-dark:      #373737;
$brand-white:     #ffffff;
$border-color:    #e6eaed;

// Type
$font-family-primary: 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Bitter', serif;
$font-size-base: 14px;

$font-size-xxs:  $font-size-base * 0.75;  // ~ 12px
$font-size-xs:   $font-size-base * 0.85;  // ~ 12px
$font-size-sm:   $font-size-base * 0.93;  // ~ 13px
$font-size-lg:   $font-size-base * 1.15;  // ~ 16px
$font-size-xlg:  $font-size-base * 1.44;  // ~ 20px
$font-size-xl:   $font-size-base * 1.57;  // ~ 22px
$font-size-xxl:  $font-size-base * 2;    // ~ 28px
$font-size-xxxl: $font-size-base * 2.4; // ~ 34px


$font-weight-extra-bold:    900;
$font-weight-bold:          700;
$font-weight-regular:       400;
$font-weight-light:         300;

$line-height-computed: $font-size-base * 1.42;

$text-color: #373737;
$text-muted: #7E8083;
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);

$container-sm: 640px;
$container-md: 860px;
$container-lg: 1060px;

$grid-gutter-width: 30px;

// Buttons
$btn-border-radius-base: 0;
$btn-font-family:       $font-family-primary;
$btn-font-size:         12px;
$btn-font-weight:       500;
$btn-primary-border:    $brand-primary;

// Cards
$card-default-background-color: transparent;
$card-border-color: #eee;
$card-border-width: 0;
$card-box-shadow: none;
$card-btn-prototype: '.link';
$card-link-font-weight: 400;
$card-spacer-x: 12px;
$card-spacer-y: 12px;

// Features.
$feature-icon-width: 40px;
$feature-icon-border-color: transparent;

// Nav
$navbar-client-height:           117px;

// Sections
$section-bg: transparent;
$section-block-bg: none;
$section-block-position: relative;
$section-btn-prototype: '.btn-default';
$section-cap-x: 0px;
$section-cap-y: 20px;
$section-header-bg: none;
$section-footer-bg: none;
$section-margin: 0;
$section-position: relative;
$section-prototype: 'container';
$section-hero-text-color: #fff;
$section-spacer-x: 0px;
$section-spacer-y: 30px;
$section-text-color: $text-color; // .section-text {} font color.
$section-text-color-muted: $text-muted; // .section-text--secondary {}
$section-title-border: 1px solid #eee;
$section-title-font-family: $font-family-primary;
$section-title-font-size: $font-size-xxl;
$section-title-font-weight: 700;
$section-title-line-height: 1.3;
$section-title-text-align: left;

// Webidentities
$webidentity-width: 29px;

// RS colors
$brand-dailymotion:     #13B1FC;
$brand-facebook:        #3b5998;
$brand-flickr:          #ff0084;
$brand-googleplus:      #dd4b39;
$brand-instagram:       #e1306c;
$brand-linkedin:        #0077b5;
$brand-pinterest:       #bd081c;
$brand-rss:             #f26522;
$brand-tiktok:          #000000;
$brand-twitter:         #000000;
$brand-viadeo:          #f07355;
$brand-youtube:         #ff0000;
$brand-sharing:         #ED4D4D;
$brand-soundcloud:      #ff8800;
$brand-email:           $brand-primary;

$webidentities: (
  'dailymotion': (
    'bg-color': $brand-dailymotion,
    'border-color': $brand-dailymotion,
    'color': #fff,
  ),
  'facebook': (
    'bg-color': $brand-facebook,
    'border-color': $brand-facebook,
    'color': #fff,
  ),
  'flickr': (
    'bg-color': $brand-flickr,
    'border-color': $brand-flickr,
    'color': #fff,
  ),
  'google-plus': (
    'bg-color': $brand-googleplus,
    'border-color': $brand-googleplus,
    'color': #fff,
  ),
  'instagram': (
    'bg-color': $brand-instagram,
    'border-color': $brand-instagram,
    'color': #fff,
  ),
  'linkedin': (
    'bg-color': $brand-linkedin,
    'border-color': $brand-linkedin,
    'color': #fff,
  ),
  'pinterest': (
    'bg-color': $brand-pinterest,
    'border-color': $brand-pinterest,
    'color': #fff,
  ),
  'rss': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'rss-two': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'soundcloud': (
    'bg-color': $brand-soundcloud,
    'border-color': $brand-soundcloud,
    'color': #fff,
  ),
  'tiktok': (
    'bg-color': $brand-tiktok,
    'border-color': $brand-tiktok,
    'color': #fff,
  ),
  'twitter': (
    'bg-color': $brand-twitter,
    'border-color': $brand-twitter,
    'color': #fff,
  ),
  'viadeo': (
    'bg-color': $brand-viadeo,
    'border-color': $brand-viadeo,
    'color': #fff,
  ),
  'youtube': (
    'bg-color': $brand-youtube,
    'border-color': $brand-youtube,
    'color': #fff,
  ),
);
