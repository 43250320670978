.access--skip-link {
  position: absolute;
  background-color: $brand-primary;
  font-size: $font-size-sm;
  padding: 8px;
  top: -1000px;
  left: -1000px;
  z-index: 100;
  cursor: pointer;
  transition: left .3s;

  &:focus {
    position: fixed;
    color: $brand-white;
    left: 0;
    top: 0;
    outline: none;
  }
}
