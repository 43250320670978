.panel-widget {
  float: left;
}

.panel-widget--collapse-indicator {
  float: right;

  .wizicon {
    transition: transform 0.3s;
  }
}

.panel--web_publication_collapse.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(-180deg);
}

.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(0deg);
}

.panel--expert_expertise,
.panel--web_publication_collapse {
  &:after {
    background-color: $border-color;
    content: '';
    height: 1px;
    bottom: 0;
    position: absolute;
    right: 0;
  }

  .panel-heading {
    background-color: #fff;
  }

  .panel-title {
    @include make-font-title--4();

    line-height: 25px;
    padding: 17px 20px;
  }

  .panel-widget--collapse-index {
    .panel-widget-item {
      @include size(59px, 59px);
      @include make-font-title--4();

      line-height: 1;
      padding: 20px;
      text-align: right;
    }
  }

  .panel-widget-item {
    padding: 20px;
  }

  &.in {
    &:after {
      left: 0px;
    }

    .panel-heading,
    .panel-collapse {
      background-color: $brand-light;
    }
  }

  &.panel--highlight {
    .panel-title,
    .panel-widget--collapse-index .panel-widget-item {
      color: $brand-primary;
    }
  }

  .collapse-body {
    padding: 20px;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .panel-title {
      padding-left: 0px;
      padding-right: 0px;
    }

    .collapse-body {
      padding: 10px 65px 25px;
    }
  }
}

.panel--expert_expertise {
  &:after {
    left: 0;
  }

  .panel-widget--dates {
    @include make-font-9();
    float: left;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    width: 220px;

    @media screen and (max-width: $grid-float-breakpoint) {
      float: none;
    }
  }

  .panel-body {
    padding-left: 220px;

    @media screen and (max-width: $grid-float-breakpoint) {
      padding-left: 20px;
    }
  }

  &.in {
    .panel-heading .wizicon-collapse-indicator {
      transform: rotate(-180deg);
      transition: transform ease-in 0.3s;
    }
  }

  &:last-child:after {
    content: none;
  }
}

.panel--web_publication_collapse {
  &:after {
    left: 60px;
  }
}

.panel-group--program {
  .panel--program:last-child .panel-title {
    margin-bottom: 0;
  }
}

.panel--program {
  .panel-program--body {
    margin-bottom: 10px;
  }

  .panel-heading {
    background-color: $brand-white;
  }

  .panel-title {
    @include make-font-11();
    background-color: $brand-light;
    color: $brand-dark;
    margin-bottom: 10px;

    .panel-title-icon {
      float: right;

      .wizicon {
        fill: $brand-dark;
      }
    }
  }

  .panel-body {
    background-color: $brand-white;
    padding: 0;
  }

  .panel--subprogram {
      border-bottom: 1px solid $border-color;

    .panel-heading {
      background-color: $brand-white;

      .panel-title {
        background-color: inherit;
        color: inherit;
        padding: 20px;
        margin-bottom: 0;
      }

      .panel-widget--dates {
        color: $brand-primary;
        float: left;
        width: 18%;

        .panel-widget-item {
          padding: 20px;
          display: flex;
          align-items: center;
        }

        .wizicon {
          @include size(13px, 13px);
          color: $brand-primary;
          fill: $brand-primary;
          line-height: 1;
        }
      }

      .panel-widget--performers {
        float: right;

        .mediaobj--performer img {
          width: 30px;
        }

        .panel-widget-item {
          padding: 10px 5px;
        }
      }

      .panel-widget--collapse-indicator {
        float: right;
        transition: transform 0.3s ease-out;
      }
    }

    .panel-collapse {
      .panel-body {
        margin-left: 18%;
        padding: 20px;

        .panel-text {
          font-size: $font-size-base;
        }
      }
    }
  }

  &.in {
    .panel-title {
      background-color: $brand-dark;
      color: $brand-white;

      .panel-title-icon {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;

        .wizicon {
          fill: $brand-light;
        }
      }
    }

    .panel--subprogram.in {
      .panel-widget--collapse-indicator {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;
      }
    }
  }
}

.panel--content-download {
  border: 1px solid $brand-secondary;


  @media screen and (min-width: $grid-float-breakpoint) {
    @include make-block-center(500px);
  }

  .panel-heading {
    background: transparent;
  }

  .panel-title {
    @include make-font-9();

    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
  }

  &.in .panel-title,
  .panel-title[href]:hover {
    background: $brand-secondary;
    color: #fff;

    .wizicon {
      fill: #fff;
    }
  }

  .panel-collapse {
    border-top: 1px solid $brand-secondary;
  }
}
