@font-face {
    font-family: 'Gilroy';
    font-weight: 300;
    font-style: normal;
    src: url('../../../fonts/Gilroy-Light.woff') format('woff')
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 400;
    font-style: normal;
    src: url('../../../fonts/35CE3C_6_0.woff') format('woff')
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 500;
    font-style: normal;
    src: url('../../../fonts/35CE3C_9_0.woff') format('woff')
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 600;
    font-style: normal;
    src: url('../../../fonts/Gilroy-SemiBold.woff') format('woff')
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 700;
    font-style: normal;
    src: url('../../../fonts/35CE3C_11_0.woff') format('woff')
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 800;
    font-style: normal;
    src: url('../../../fonts/Gilroy-ExtraBold.woff') format('woff')
}
