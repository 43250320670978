@mixin section-huer($title-color, $bg-color, $bg-reverse, $bg-image: '') {
  @if $bg-image != '' {
    &:before {
      content: none; // cancel the angled edge.
    }

    background-image: $bg-image;
    // Assume its background cover always for now.
      -moz-background-size: cover;
      -webkit-background-size: cover;
    background-size: cover;
  } @else {
    background-color: $bg-color;
    background-image: none;

    padding-bottom: $section-spacer-y;
    padding-top: $section-spacer-y / 2;
  }

  .section-title {
    color: $title-color;
  }
}

@mixin section-decorator($color, $height, $width, $leftOffset: auto, $rightOffset: auto) {
  padding-bottom: $section-cap-y;
  position: relative;

  &:after {
    background-color: $color;
    bottom: -1px;
    content: '';
    height: $height;
    position: absolute;
    left: $leftOffset;
    right: $rightOffset;
    width: $width;
  }
}
